@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

html, body {
  height: 100%;
}

.app {
  height: 100%;
}

body {
  background: #f0efec;
  margin: 0;
  font-family: 'DMSans';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "DMSans";
  src: local("DMSans"),
    url(./assets/fonts/DMSans-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "DMSans Italic";
  src: local("DMSans Italic"),
    url(./assets/fonts/DMSans-Italic.ttf) format("truetype");
}

@font-face {
  font-family: "DMSans Medium";
  src: local("DMSans Medium"),
    url(./assets/fonts/DMSans-Medium.ttf) format("truetype");
}

@font-face {
  font-family: "DMSans MediumItalic";
  src: local("DMSans MediumItalic"),
    url(./assets/fonts/DMSans-MediumItalic.ttf) format("truetype");
}

@font-face {
  font-family: "DMSans Bold";
  src: local("DMSans Bold"),
    url(./assets/fonts/DMSans-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "DMSans BoldItalic";
  src: local("DMSans BoldItalic"),
    url(./assets/fonts/DMSans-BoldItalic.ttf) format("truetype");
}

h1 {
  font-size: 48px;
  font-family: 'DMSans Bold';
}

.wrapper {
  width: 100%;
  height: 100%;
}